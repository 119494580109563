import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Dropdown, Table } from "react-bootstrap";
import Status from "./Status";
import useAuth from "../../hooks/useAuth";
import OrderDeleteModal from "./OrderDeleteModal";
import { formatDateShort } from "../../settings/dateTime";

const OrdersTable = ({ orders, setFetchKey, withoutClient, filters }) => {
  const [selectedOrderToDelete, setSelectedOrderToDelete] = useState();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const { isUserBoss, isUserReader } = useAuth();
  const [filtersWithoutSearch, setFiltersWithousSearch] = useState(false);

  useEffect(() => {
    const checkFiltersWithoutSearch = () => {
      if (!filters) {
        return false; // Handle case where filters is undefined or null
      }
      const { search, ...otherFilters } = filters;
      const hasFilters = Object.values(otherFilters).some(
        (value) => value !== null && value !== undefined && value !== ""
      );
      return hasFilters;
    };
    setFiltersWithousSearch(checkFiltersWithoutSearch());

    console.log(filtersWithoutSearch);
  }, [filters, orders]);

  const handleDeleteButtonClick = (order) => {
    setSelectedOrderToDelete(order);
    setDeleteModalShow(true);
  };

  const handleDelete = () => {
    setFetchKey((prevKey) => prevKey + 1);
    setDeleteModalShow(false);
  };

  return (
    <div className="border-top">
      <Table className="p-0 m-0" striped hover>
        <thead>
          {filtersWithoutSearch && (
            <tr className="table-secondary">
              <th>{orders.count}</th>
              <th colSpan={3}></th>
              <th>
                {orders.total_area
                  ? orders.total_area.toLocaleString("ru-RU")
                  : 0}
              </th>
              <th colSpan={7}></th>
              <th>
                {orders.total_amount
                  ? orders.total_amount.toLocaleString("ru-RU")
                  : 0}
              </th>
              <th>
                {orders.total_debts
                  ? orders.total_debts.toLocaleString("ru-RU")
                  : 0}
              </th>
              <th colSpan={2}></th>
            </tr>
          )}
          <tr>
            <th>№</th>
            <th>Дата</th>
            {!withoutClient && <th>Клиент</th>}
            <th>Вид стекла</th>
            <th>Кв.м</th>
            <th>Кол-во</th>
            <th>Срок</th>
            <th>Стекло</th>
            <th>Закалка</th>
            <th>Доставка</th>
            <th>Аксесс</th>
            <th>Сумма</th>
            <th>Оплачено</th>
            <th>Остаток</th>
            <th>Статус</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders?.results.map((order) => (
            <tr key={order.id} className={order.deleted && "table-danger"}>
              <th scope="row">{order.order_number}</th>
              <td>{formatDateShort(order.date)}</td>
              {!withoutClient && (
                <td>
                  <Link to={`/clients/${order.client.id}`}>
                    {order.client.name}
                  </Link>
                </td>
              )}
              <td>{order.glass || "-"}</td>
              <td>{order.area || 0}</td>
              <td>{order.quantity | 0}</td>
              <td>{formatDateShort(order.due_date)}</td>
              <td>{order.glass_price || 0}</td>
              <td>{order.tempering_price || 0}</td>
              <td>{order.delivery_price || 0}</td>
              <td>{order.accessories_price || 0}</td>
              <td className="fw-bold">$ {order.amount}</td>
              <td className="fw-bold">$ {order.total_payment | 0}</td>
              <td>
                {order.payment_balance > 0 ? (
                  <HashLink
                    to={`/orders/${order.id}#payment`}
                    className="text-danger fw-bold"
                  >
                    $ {order.payment_balance}
                  </HashLink>
                ) : order.payment_balance === 0 ? (
                  <span className="badge bg-success">Оплачен</span>
                ) : (
                  <span className="text-success fw-bold">
                    $ {order.payment_balance}
                  </span>
                )}
              </td>
              <td>
                <Status order={order} />
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="" className="py-0">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="text-center">
                    <Dropdown.Item>
                      <Link
                        className="dropdown-item text-primary"
                        to={`/orders/${order.id}`}
                      >
                        <i className="fa-solid fa-file-lines"></i>
                      </Link>
                    </Dropdown.Item>
                    {!isUserReader && (
                      <>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <Dropdown.Item>
                          <Link
                            className="dropdown-item text-secondary"
                            to={`/orders/${order.id}/update`}
                          >
                            <i className="fa-solid fa-file-pen"></i>
                          </Link>
                        </Dropdown.Item>
                      </>
                    )}
                    {isUserBoss && (
                      <>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <Dropdown.Item>
                          <Link
                            className="dropdown-item text-danger"
                            onClick={() => handleDeleteButtonClick(order)}
                          >
                            <i className="fa-solid fa-trash"></i>
                          </Link>
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {selectedOrderToDelete && (
        <OrderDeleteModal
          order={selectedOrderToDelete}
          show={deleteModalShow}
          setShow={setDeleteModalShow}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default OrdersTable;
